import React from 'react';
import { SxProps } from '@mui/material';
import { FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectFieldForm } from 'src/components/forms/SelectFieldForm';
import { getCapitalisationBaremeSourceLabel } from 'src/helpers/bareme/bareme';
import { Bareme } from 'src/types/bareme.type';
interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  baremes: Bareme[];
  label?: string;
  sx?: SxProps;
  isReferentiel?: boolean;
  getBaremeSourceLabelFunction?: (bareme: Bareme) => string;
  afterOnChange?: (value?: string) => void;
}

export const SelectBareme = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  baremes,
  label,
  afterOnChange,
  getBaremeSourceLabelFunction,
  sx,
  isReferentiel,
}: Props<TFieldValues, TName>) => {
  const { t } = useTranslation();

  return (
    <SelectFieldForm
      control={control}
      name={name}
      sx={{ minWidth: label ? 300 : 268, ...sx }}
      allowNoSelection
      noSelectionText={
        isReferentiel
          ? t(
              'prejudice.capitalisation.form.fields.bareme.noSelectionTextReferentiel',
            )
          : t(
              'prejudice.capitalisation.form.fields.bareme.noSelectionTextBareme',
            )
      }
      label={label}
      InputLabelProps={{ shrink: true }}
      options={baremes.map((bareme) => ({
        label: getBaremeSourceLabelFunction
          ? getBaremeSourceLabelFunction(bareme)
          : getCapitalisationBaremeSourceLabel(bareme),
        value: bareme._id,
      }))}
      afterOnChange={afterOnChange}
    />
  );
};
