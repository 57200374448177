export const baremeTypes = [
  'Indemnisation',
  'RII',
  'Capitalisation',
  'Scolaire',
  'Affection',
  'EsperanceDeVie',
] as const;

export const baremeSources = [
  'ONIAM',
  'Mornet',
  'GDP',
  'BCRIV',
  'CJ',
  'FGTI',
  'Quezel',
  'INSEE',
] as const;

export type BaremeType = (typeof baremeTypes)[number];
export type BaremeSource = (typeof baremeSources)[number];

export const baremeIndemnisationDegrees = [
  '1/7',
  '2/7',
  '3/7',
  '4/7',
  '5/7',
  '6/7',
  '7/7',
  '',
] as const;

export type BaremeIndemnisationDegree =
  (typeof baremeIndemnisationDegrees)[number];

export const baremeIndemnisationDegreeLabels = [
  'très léger',
  'léger',
  'modéré',
  'moyen',
  'assez important',
  'important',
  'très important',
  'exceptionnel',
] as const;

export type BaremeIndemnisationDegreeLabel =
  (typeof baremeIndemnisationDegreeLabels)[number];

export const genders = ['man', 'woman', 'all', 'undetermined'] as const;
export type Gender = (typeof genders)[number];

export type BaremeIndemnisationValues = {
  degree: BaremeIndemnisationDegree;
  degreeLabel: BaremeIndemnisationDegreeLabel;
  fromValue: number;
  toValue: number;
  average?: number;
  value?: number;
  pointPrice?: number;
};
const baremeRIIValuesTypes = ['pointPrice', 'percentage'];
export type BaremeRIIValuesType = (typeof baremeRIIValuesTypes)[number];

export type BaremeRIIValue = {
  gender: Gender;
  pointPrices?: {
    degree: number;
    pointPrice: number;
  }[];
  type: BaremeRIIValuesType;
  rows: {
    fromAge?: number;
    toAge?: number;
    age?: number;
    columns: {
      percentage?: number;
      fromPercentage?: number;
      toPercentage?: number;
      value: number;
    }[];
  }[];
};

export type BaremeCapitalisationValue = {
  gender: Gender;
  rows: {
    ageDateAttribution: number;
    columns: {
      ageLastArrerage: number | null;
      isLastArrerageViagere: boolean;
      percentage: number;
    }[];
  }[];
};

export type BaremeScolaireValue = {
  itt: BaremeScolaireITT;
  label?: string;
  levels?: {
    level: BaremeScolaireLevel;
    value: number;
  }[];
};

export type BaremeEsperanceDeVieValue = {
  gender: Gender;
  values: Record<number, Record<number, number>>;
};

export type BaremeValues =
  | BaremeIndemnisationValues[]
  | BaremeRIIValue[]
  | BaremeCapitalisationValue[]
  | BaremeScolaireValue[]
  | BaremeAffectionValue[]
  | BaremeEsperanceDeVieValue[];

export const baremeAdditionalPropertiesPrejudiceTypes = [
  'PREJUDICE_ESTHETIQUE',
  'SOUFFRANCES_ENDUREES',
] as const;

const baremeAdditionalPropertiesTypeTaux = [
  'stationnaire',
  'prospectif',
] as const;

export type BaremeAdditionalPropertiesPrejudiceType =
  (typeof baremeAdditionalPropertiesPrejudiceTypes)[number];

export type BaremeAdditionalPropertiesTypeTaux =
  (typeof baremeAdditionalPropertiesTypeTaux)[number];

export type BaremeAdditionalProperties = {
  taux?: number;
  prejudice?: BaremeAdditionalPropertiesPrejudiceType;
  typeTaux?: BaremeAdditionalPropertiesTypeTaux;
};

export interface CJSourceComments {
  esperanceDeVie: string;
}
export type SourceComments = CJSourceComments;

export const baremeScolaireLevels = [
  'ECOLIER',
  'COLLEGIEN',
  'LYCEEN',
  'ETUDIANT',
];

export type BaremeScolaireLevel = (typeof baremeScolaireLevels)[number];

export const baremeScolaireITTs = ['courteDuree', 'perteAnneeScolaire'];

export type BaremeScolaireITT = (typeof baremeScolaireITTs)[number];

const baremeAffectionAyantDroits = [
  'conjointConcubin',
  'enfant',
  'frereSoeur',
  'parent',
  'grandParent',
  'petitEnfant',
  'autreParentOuProche',
] as const;
type BaremeAffectionAyantDroit = (typeof baremeAffectionAyantDroits)[number];

const baremeAffectionVictimeDecedees = [
  'conjointConcubin',
  'enfant',
  'frereSoeur',
  'parent',
  'grandParent',
  'petitEnfant',
  'autreParentOuProche',
] as const;
type BaremeAffectionVictimeDecedee =
  (typeof baremeAffectionVictimeDecedees)[number];

const baremeAffectionAyantDroitAndVictimeTypes = [
  'OUTSIDE_HOME',
  'INSIDE_HOME',
  'MAJOR_INSIDE_HOME',
  'MAJOR_OUTSIDE_HOME',
  'REGULAR_RELATIONS',
  'NO_REGULAR_RELATIONS',
  'REGULAR_RELATIONS',
  'FROM_25_INSIDE_HOME',
  'FROM_25_OUTSIDE_HOME',
  'TO_25',
  'REGULAR_RELATIONS',
  'NO_REGULAR_RELATIONS',
  'COHABITATION',
  'NO_COHABITATION',
  'MINOR',
  'MAJOR',
] as const;
type BaremeAffectionAyantDroitAndVictimeType =
  (typeof baremeAffectionAyantDroitAndVictimeTypes)[number];

export interface BaremeAffectionIndemnite {
  fromValue?: number;
  toValue?: number;
  value?: number;
  label?: string;
}

export interface BaremeAffectionValue {
  ayantDroit: BaremeAffectionAyantDroit;
  victimeDecedee: BaremeAffectionVictimeDecedee;
  indemnite: BaremeAffectionIndemnite;
  indemniteCoursDassise?: BaremeAffectionIndemnite;
  ayantDroitType?: BaremeAffectionAyantDroitAndVictimeType;
  victimeType?: BaremeAffectionAyantDroitAndVictimeType;
}

export interface Bareme {
  _id: string;
  type: BaremeType;
  source: BaremeSource;
  name: string;
  year: number;
  otherYears?: number[];
  sourceComment?: string;
  otherSourceComments?: SourceComments;
  values: BaremeValues;
  additionalProperties?: BaremeAdditionalProperties;
  isExtrapolated?: boolean;
  updatedAt: Date;
}
