import { Bareme, BaremeIndemnisationValues } from 'src/types/bareme.type';
import { PrejudiceFormBase } from 'src/types/prejudice.type';

export const prejudiceBaseDefaultValues = ({
  values,
}: {
  values: PrejudiceFormBase | undefined;
}): PrejudiceFormBase => ({
  notes: values?.notes || '',
  chiffrage: values?.chiffrage || 'en_cours',
  formVersion: values?.formVersion || 0,
  editedFields: values?.editedFields || [],
});
export type BaremeDefaultMontant = Bareme & {
  values: BaremeIndemnisationValues[];
};
