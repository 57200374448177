import { Balance } from '@mui/icons-material';
import { Tooltip, IconButton } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

interface Props {
  onClick: () => void;
}

export const DintilhacButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Tooltip title={t('prejudice.fields.dintilhac.buttonText')}>
      <IconButton
        onClick={onClick}
        color="primary"
        sx={{
          borderRadius: '50%',
          border: 'primary.main',
          borderStyle: 'solid',
          borderWidth: 2,
          padding: '4px',
        }}
      >
        <Balance />
      </IconButton>
    </Tooltip>
  );
};
