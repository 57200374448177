import React from 'react';
import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  CreateOrganizationDto,
  OrganizationType,
} from 'src/types/organization.type';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TextFieldForm } from 'src/components/forms/TextFieldForm';
import { SelectFieldForm } from 'src/components/forms/SelectFieldForm';
import i18next from 'i18next';
import { useAppDispatch } from 'src/hooks/store';
import { createOrganization } from 'src/slices/organization';
import {
  useDisplayedOrganization,
  useOrganizations,
} from 'src/hooks/store/organization.hooks';
import { useNavigate } from 'react-router-dom';

export const CreateOrganization: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { organizations } = useOrganizations();
  const { setDisplayedOrganization } = useDisplayedOrganization();
  const { control, handleSubmit } = useForm<CreateOrganizationDto>({
    defaultValues: {
      name: '',
      type: undefined,
    },
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required(),
        type: yup
          .string()
          .oneOf(
            Object.values(OrganizationType),
            i18next.t('organization.fields.type.validation.oneOf'),
          )
          .required(),
      }),
    ),
  });
  const onCancel = () => {
    if (organizations[0]?._id) {
      setDisplayedOrganization(organizations[0]._id);
    } else {
      navigate('/invitations');
    }
  };
  const onSubmit = (data: CreateOrganizationDto) => {
    dispatch(createOrganization({ data }))
      .unwrap()
      .then((organization) => {
        setDisplayedOrganization(organization._id);
      });
  };
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      padding={2}
      sx={{ height: '100%' }}
    >
      <Card sx={{ width: '100%', maxWidth: 400 }}>
        <CardContent>
          <Typography variant="h4" textAlign="center">
            {t('pages.CreateOrganization.title')}
          </Typography>
          <Typography variant="body1" marginTop={3}>
            {t('pages.CreateOrganization.description')}
          </Typography>
          <Stack
            spacing={2}
            direction="column"
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            marginTop={4}
          >
            <TextFieldForm
              control={control}
              name="name"
              label={t('organization.fields.name.label')}
            />
            <SelectFieldForm
              control={control}
              name="type"
              options={Object.values(OrganizationType).map((value) => ({
                value,
                label: t(`organization.fields.type.options.${value}`),
              }))}
              allowNoSelection
              noSelectionText={t('organization.fields.type.noSelectionText')}
            />
            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Button onClick={onCancel} variant="outlined">
                {t('common.cancel')}
              </Button>
              <Button type="submit" variant="contained">
                {t('forms.save')}
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};
