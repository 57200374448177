import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AskForQuote } from 'src/components/subscription/AskForQuote';
import { isAutomaticSubscriptionAvailableForOrganization } from 'src/helpers/subscription';
import { useDisplayedOrganization } from 'src/hooks/store/organization.hooks';

interface Props {
  isOrganizationActivation?: boolean;
}

export const OrganizationQuote: React.FC<Props> = ({
  isOrganizationActivation,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { organization, organizationId } = useDisplayedOrganization();
  const automaticSubscriptionAvailableForOrganization =
    !!organization &&
    isAutomaticSubscriptionAvailableForOrganization(organization);
  const onClickGoToAutomaticSubscription = () => {
    if (!organizationId) {
      return;
    }
    navigate(`/organization/${organizationId}/subscription`);
  };
  return (
    <Stack spacing={2} alignItems="center" justifyContent="center" padding={2}>
      <Typography variant="h4" marginBottom={2}>
        {isOrganizationActivation
          ? t('pages.OrganizationQuote.title')
          : t('pages.OrganizationQuote.maxNumberOfUsers.title')}
      </Typography>
      <Card sx={{ width: 400 }}>
        <CardContent>
          <Typography variant="h6" marginBottom={2}>
            {t('pages.OrganizationQuote.quote.title')}
          </Typography>
          <AskForQuote />
        </CardContent>
      </Card>
      {/* Hidden for now
       <Card sx={{ width: 400 }}>
        <CardContent>
          <Typography variant="h6" marginBottom={2}>
            {t('pages.OrganizationQuote.scheduleMeeting.title')}
          </Typography>
          <Stack
            component="a"
            href="https://calendly.com/clara-norma"
            target="_blank"
            rel="noreferrer"
            direction="row"
            alignItems="center"
            spacing={1}
          >
            <CalendarIcon />
            <Typography>
              {t('pages.OrganizationQuote.scheduleMeeting.link')}
            </Typography>
          </Stack>
        </CardContent>
      </Card> */}
      {automaticSubscriptionAvailableForOrganization ? (
        <Card sx={{ width: 400 }}>
          <CardContent>
            <Typography variant="h6" marginBottom={2}>
              {t('pages.OrganizationQuote.automaticSubscription.title')}
            </Typography>
            <Button
              variant="contained"
              onClick={onClickGoToAutomaticSubscription}
            >
              {t('pages.OrganizationQuote.automaticSubscription.button')}
            </Button>
          </CardContent>
        </Card>
      ) : null}
    </Stack>
  );
};
