import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Stack,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { fDate } from 'src/helpers/formatTime';
import { Organization } from 'src/types/organization.type';

interface ArchiveDialogProps {
  organization: Organization;
  open: boolean;
  onClose: () => void;
}

export const OrganizationArchiveDialog: React.FC<ArchiveDialogProps> = ({
  organization,
  open,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {t('organization.fields.organizationArchiveList', {
          organizationName: organization?.name,
        })}
      </DialogTitle>
      <DialogContent
        sx={{ width: '700px', height: '700px', overflowY: 'hidden' }}
      >
        <Stack spacing={2}>
          <List>
            {organization.subscription?.subscriptionPeriods?.map(
              (period, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`${t('pages.Admin.organization.subscriptionPeriodStartDate')} ${fDate(period.startDate)}`}
                    secondary={`${t('pages.Admin.organization.subscriptionPeriodEndDate')} ${fDate(period.endDate)}`}
                  />
                </ListItem>
              ),
            )}
          </List>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
