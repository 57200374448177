import i18next from 'i18next';
import { fCurrency } from 'src/helpers/formatNumber';
import {
  formatSmicInfo,
  getDisplayedQuotientRevalorisations,
  getIndicesRevalorisation,
  getMontantRevalorise,
  getRevalorisationsSmics,
} from 'src/helpers/prejudices/revalorisation';

export const getMontantRevalorisePrint = ({
  displayStar = true,
  montantsDejaRevalorises = false,
  ...parameters
}: Parameters<typeof getMontantRevalorise>[0] & {
  displayStar?: boolean;
  montantsDejaRevalorises: boolean;
}): string => {
  if (montantsDejaRevalorises) {
    return fCurrency(parameters.montant);
  }
  const montantRevalorise = getMontantRevalorise(parameters);
  if (montantRevalorise === null) {
    return fCurrency(parameters.montant);
  } else if (montantRevalorise === 0) {
    return fCurrency(montantRevalorise);
  }
  return `${fCurrency(montantRevalorise)}${displayStar ? ' *' : ''}`;
};

export const getQuotientRevalorisationPrint = (
  parameters: Parameters<typeof getDisplayedQuotientRevalorisations>[0],
): string => {
  switch (parameters.coefficientsType) {
    default:
    case 'annuel':
    case 'mensuel': {
      const indices = getIndicesRevalorisation(parameters);
      if (indices) {
        return (
          i18next.t('prejudice.revalorisation.quotient.indices', {
            indiceLiquidation: indices.indiceLiquidation,
            indiceMontant: indices.indiceMontant,
          }) || ''
        );
      }
      return '';
    }
    case 'smic':
      const smics = getRevalorisationsSmics(parameters);
      if (smics?.smicAnneeLiquidation && smics?.smicAnneeMontant) {
        return (
          i18next.t('prejudice.revalorisation.quotient.smics.print', {
            smicLiquidation: formatSmicInfo(smics.smicAnneeLiquidation),
            smicMontant: formatSmicInfo(smics.smicAnneeMontant),
          }) || ''
        );
      }
      return '';
  }
};
