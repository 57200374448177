import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Organization,
  OrganizationMemberRole,
  UpdateOrganizationDto,
} from 'src/types/organization.type';
import * as yup from 'yup';
import { TextFieldForm } from '../forms/TextFieldForm';
import { CheckboxFormField } from '../forms/CheckboxFormField';
import { DeleteConfirmationDialog } from '../basic/DeleteConfirmationDialog';
import { isUserOrganizationOwner } from 'src/helpers/organization';
import { useAuthenticatedUser } from 'src/hooks/store/auth.hooks';
interface Props {
  open: boolean;
  onClose: () => void;
  organization: Organization | null;
  onSubmit: (data: UpdateOrganizationDto) => void;
  onDelete: () => void;
}

export const UpdateOrganizationDialog: React.FC<Props> = ({
  open,
  onClose,
  organization,
  onSubmit,
  onDelete,
}) => {
  const { t } = useTranslation();
  const user = useAuthenticatedUser();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const { control, handleSubmit, reset } = useForm<UpdateOrganizationDto>({
    defaultValues: {
      name: organization?.name,
      forbidNonOrganizationEmails: organization?.forbidNonOrganizationEmails,
      hideSensitiveFields: organization?.hideSensitiveFields,
    },
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required().max(50),
        forbidNonOrganizationEmails: yup.boolean(),
        hideSensitiveFields: yup.boolean(),
      }),
    ),
  });

  useEffect(() => {
    if (organization && open) {
      reset({
        name: organization.name,
        forbidNonOrganizationEmails: organization.forbidNonOrganizationEmails,
        hideSensitiveFields: organization.hideSensitiveFields,
      });
    }
  }, [open]);

  const isOrganizationOwner =
    organization && user && isUserOrganizationOwner(organization, user);

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          {t('pages.OrganizationConfiguration.dialog.updateOrganization.title')}
        </DialogTitle>
        <DialogContent>
          <Stack
            component="form"
            spacing={2}
            onSubmit={handleSubmit(onSubmit)}
            justifyContent="center"
            alignItems="start"
          >
            <TextFieldForm name="name" control={control} fullWidth />
            <FormControlLabel
              control={
                <CheckboxFormField
                  name="forbidNonOrganizationEmails"
                  control={control}
                />
              }
              label={t(
                'organization.fields.forbidNonOrganizationEmails.label',
                {
                  emailDomain:
                    organization?.members
                      .find(
                        (member) =>
                          member.role === OrganizationMemberRole.owner,
                      )
                      ?.email?.split('@')?.[1] || '',
                },
              )}
            />
            <FormControlLabel
              control={
                <CheckboxFormField
                  name="hideSensitiveFields"
                  control={control}
                />
              }
              label={t('organization.fields.hideSensitiveFields.label')}
            />
            <Stack
              direction="row"
              justifyContent={
                isOrganizationOwner ? 'space-between' : 'flex-end'
              }
              width="100%"
            >
              {isOrganizationOwner ? (
                <Button
                  onClick={() => setIsDeleteDialogOpen(true)}
                  variant="contained"
                  color="error"
                >
                  {t(
                    'pages.OrganizationConfiguration.dialog.updateOrganization.delete.buttonText',
                  )}
                </Button>
              ) : null}
              <Button type="submit" variant="contained">
                {t('forms.save')}
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      {organization && (
        <DeleteConfirmationDialog
          open={isDeleteDialogOpen}
          title={t(
            'pages.OrganizationConfiguration.dialog.updateOrganization.deleteDialog.title',
            {
              organizationName: organization?.name,
            },
          )}
          description={t(
            'pages.OrganizationConfiguration.dialog.updateOrganization.deleteDialog.description',
          )}
          onCancel={() => setIsDeleteDialogOpen(false)}
          onConfirm={() => {
            onDelete();
            setIsDeleteDialogOpen(false);
            onClose();
          }}
          entityText={organization.name}
        />
      )}
    </>
  );
};
