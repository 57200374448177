import { Paragraph, Table, TableOfContents } from 'docx';
import i18next from 'i18next';
import {
  CalculsFormCalendrier,
  CalculsFormCapitalisation,
  CalculsGlobal,
} from 'src/constants/calculs';
import { fCurrency } from 'src/helpers/formatNumber';
import { fDate } from 'src/helpers/formatTime';
import { fCoefficientCapitalisation } from 'src/helpers/formatValues';
import {
  getAgeDateAttribution,
  getAllCapitalisationCoefficient,
} from 'src/helpers/prejudices/capitalisation';
import { Bareme, BaremeCapitalisationValue } from 'src/types/bareme.type';
import { MonetaryErosion } from 'src/types/monetaryErosion.type';
import {
  CapitalisationValues,
  PrejudiceFormCalendrierDepenseCapitalisation,
  PrejudiceFormCalendrierDepenseCapitalisationRow,
  PrejudiceType,
} from 'src/types/prejudice.type';
import { Procedure } from 'src/types/procedure.type';
import { Victime } from 'src/types/victime.type';
import {
  NumberingType,
  getEmptyLine,
  getParagraph,
  getTextRun,
} from '../../docxFunctions';
import { rentesPrint } from '../../prejudicesPrints/rentesPrint';
import { simpleTablePrint } from '../../simpleHorizontalTablePrint';
import { capitalisationSelectedBaremeIntroductionWrittenPrint } from '../capitalisation.written.print';
import { displayFormula } from '../formula';
import { indemniteGlobaleARepartirAEchoirWrittenPrint } from '../indemniteGlobaleARepartir/indemniteGlobaleARepartirAEchoir.written.print';
import { displayNumerosPiecesWrittenPrint } from '../numerosPieces.written.print';
import {
  getMontantRevaloriseWrittenPrint,
  getRevalorisationIntroductionWrittenPrintIfRevalorise,
} from '../revalorisation.written.print';

export const cdcWrittenPrint = ({
  victime,
  procedure,
  formData,
  monetaryErosions,
  prejudiceType,
  baremes,
}: {
  victime: Victime;
  procedure: Procedure;
  formData: PrejudiceFormCalendrierDepenseCapitalisation;
  monetaryErosions: MonetaryErosion[];
  prejudiceType: PrejudiceType;
  baremes: Bareme[];
}): (Table | TableOfContents | Paragraph)[] => {
  const totauxDepenses = CalculsFormCalendrier.totauxDepenses({
    rows: formData.rows,
    tiersPayeurs: procedure.tiersPayeurs,
    dateLiquidation: new Date(procedure.dateLiquidation || 0),
    monetaryErosions,
    isCalculCapitalisation: true,
    isVictimesIndirectes: false,
    revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
  });

  const totauxDepensesAEchoir = CalculsFormCalendrier.totauxDepensesAEchoir({
    rows: formData.rows,
    tiersPayeurs: procedure.tiersPayeurs,
    dateLiquidation: new Date(procedure.dateLiquidation || 0),
    monetaryErosions,
    isCalculCapitalisation: true,
    isVictimesIndirectes: false,
    revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
  });

  const totauxDepensesTiersPayeurs = Object.values(
    totauxDepenses.priseEnChargeTiersPayeurs,
  ).reduce((accumulateur, montant) => accumulateur + montant, 0);

  const totauxDepensesTiersPayeursAEchoir = Object.values(
    totauxDepensesAEchoir.priseEnChargeTiersPayeurs,
  ).reduce((accumulateur, montant) => accumulateur + montant, 0);

  const bareme = baremes.find(
    (bareme) => bareme._id === formData.capitalisation.bareme,
  );

  const getResteAChargeText = (
    {
      resteACharge,
      dateJustificatif,
      montantsDejaRevalorises,
      priseEnChargeTiersPayeursProratises,
      montantTotal,
    }: Pick<
      PrejudiceFormCalendrierDepenseCapitalisationRow,
      | 'resteACharge'
      | 'dateJustificatif'
      | 'montantsDejaRevalorises'
      | 'priseEnChargeTiersPayeursProratises'
      | 'montantTotal'
    >,
    depenseType: string,
    index: number,
  ): string => {
    const montants = priseEnChargeTiersPayeursProratises
      .filter((tierPayer) => tierPayer.montant > 0)
      .map((tierPayer) => tierPayer.montant);

    const resteAChargeText = !!resteACharge
      ? i18next.t(
          `prejudice.prejudicesTypes.DEPENSE_SANTE_FUTURES.writtenPrint.depense.${depenseType}.resteAcharge`,
          {
            resteACharge: fCurrency(resteACharge),
            montantEchuTP: fCurrency(
              priseEnChargeTiersPayeursProratises.reduce(
                (accumulateur, { montant }) => accumulateur + montant,
                0,
              ),
            ),
            formule: displayFormula({
              formula: [montantTotal, ...montants]
                .map((num) => fCurrency(num))
                .join(` ${'-'} `),
              options: { withParentheses: true },
              editedFieldsParameters: {
                fieldName: `rows.${index}.montantTotal`,
                formData,
              },
            }),
            revalorisation:
              procedure.dateLiquidation &&
              dateJustificatif &&
              !montantsDejaRevalorises
                ? getMontantRevaloriseWrittenPrint({
                    montant: resteACharge,
                    anneeOrDateLiquidation: procedure.dateLiquidation,
                    anneeOrDateMontant: dateJustificatif,
                    coefficientsType: formData.revalorisationCoefficientsType,
                    monetaryErosions,
                  })
                : '',
          },
        )
      : i18next.t(
          `prejudice.prejudicesTypes.DEPENSE_SANTE_FUTURES.writtenPrint.depense.${depenseType}.noResteACharge`,
        );
    return resteAChargeText;
  };

  const getDepensePonctuelleParagraphs = (
    row: PrejudiceFormCalendrierDepenseCapitalisationRow,
    index: number,
  ) => {
    const nonZeroPriseEnChargeTiersPayeurs =
      row.priseEnChargeTiersPayeurs.filter(
        (priseEnChargeTiersPayeurs) => priseEnChargeTiersPayeurs.montant !== 0,
      );

    const intro = i18next.t(
      `prejudice.prejudicesTypes.DEPENSE_SANTE_FUTURES.writtenPrint.depense.ponctuelle.intro`,
      {
        intitule:
          row.intitule.length > 0
            ? row.numerosPieces.rows.length > 0
              ? `${row.intitule} `
              : `${row.intitule}, `
            : '',
        numerosPieces: !(
          row.numerosPieces.rows.length > 0 && row.intitule.length > 0
        )
          ? ''
          : `${displayNumerosPiecesWrittenPrint(
              row.numerosPieces,
              'WithComma',
            )} `,
        value:
          new Date(row.dateDebut || 0) >
          new Date(procedure.dateLiquidation || 0)
            ? 'à échoir'
            : 'échue',
        date: row.dateDebut ? fDate(row.dateDebut) : '',
      },
    );

    const tiersPayeurText =
      intro +
      i18next.t(
        `prejudice.prejudicesTypes.DEPENSE_SANTE_FUTURES.writtenPrint.depense.ponctuelle.tiersPayeur.intro`,
        {
          montantUnitaire: fCurrency(row.montantUnitaire),
        },
      ) +
      row.priseEnChargeTiersPayeurs
        .filter((row) => row.montant !== 0)
        .map((row, index, array) => {
          const text = i18next.t(
            `prejudice.prejudicesTypes.DEPENSE_SANTE_FUTURES.writtenPrint.depense.ponctuelle.tiersPayeur.tierPayeur`,
            {
              tierPayeur: row.tiersPayeur,
              priseEnChargeTierPayeur: fCurrency(row.montant),
            },
          );
          return index === array.length - 1 ? ` et${text}.` : text;
        });

    const noTiersPayeurText =
      intro +
      i18next.t(
        `prejudice.prejudicesTypes.DEPENSE_SANTE_FUTURES.writtenPrint.depense.ponctuelle.tiersPayeur.noTiersPayeur`,
        {
          charge: fCurrency(row.resteACharge),
          revalorisation: getMontantRevaloriseWrittenPrint({
            montant: row.resteACharge,
            anneeOrDateLiquidation: procedure.dateLiquidation ?? '',
            anneeOrDateMontant: row.dateJustificatif ?? '',
            coefficientsType: formData.revalorisationCoefficientsType,
            monetaryErosions,
          }),
        },
      );

    if (nonZeroPriseEnChargeTiersPayeurs.length === 0) {
      return getParagraph({
        numbering: {
          reference: NumberingType.SIMPLE_LIST,
          level: 0,
        },
        children: getTextRun(noTiersPayeurText),
      });
    } else {
      const resteAChargeText = getResteAChargeText(row, 'ponctuelle', index);
      return getParagraph({
        numbering: {
          reference: NumberingType.SIMPLE_LIST,
          level: 0,
        },
        children: [
          ...getTextRun(tiersPayeurText),
          ...getTextRun({ break: 1 }),
          ...getTextRun(resteAChargeText),
        ],
      });
    }
  };

  const getDepenseRecurrenteParagraphs = (
    row: PrejudiceFormCalendrierDepenseCapitalisationRow,
    index: number,
  ) => {
    const nonZeroPriseEnChargeTiersPayeurs =
      row.priseEnChargeTiersPayeurs.filter(
        (priseEnChargeTiersPayeurs) => priseEnChargeTiersPayeurs.montant !== 0,
      );

    const intro =
      i18next.t(
        `prejudice.prejudicesTypes.DEPENSE_SANTE_FUTURES.writtenPrint.depense.recurrente.intro`,
        {
          intitule:
            row.intitule.length > 0
              ? row.numerosPieces.rows.length > 0
                ? `${row.intitule} `
                : `${row.intitule}, `
              : '',
          numerosPieces: !(
            row.numerosPieces.rows.length > 0 && row.intitule.length > 0
          )
            ? ''
            : `${displayNumerosPiecesWrittenPrint(
                row.numerosPieces,
                'WithComma',
              )} `,
          montantUnitaire: fCurrency(row.montantUnitaire),
        },
      ) +
      (row.frequenceMontant == 'viagere'
        ? i18next.t(
            `prejudice.prejudicesTypes.DEPENSE_SANTE_FUTURES.writtenPrint.depense.recurrente.frequence.annee`,
            {
              value: row.renouvellementMaintenance,
            },
          )
        : i18next.t(
            `prejudice.prejudicesTypes.DEPENSE_SANTE_FUTURES.writtenPrint.depense.recurrente.frequence.periode`,
            {
              value: row.quantite,
              frequenceMontant: row.frequenceMontant,
            },
          )) +
      (!!row.dateFin && !!row.dateDebut
        ? i18next.t(
            `prejudice.prejudicesTypes.DEPENSE_SANTE_FUTURES.writtenPrint.depense.recurrente.frequence.dateDeFin`,
            {
              dateDebut: fDate(row.dateDebut),
              dateFin: fDate(row.dateFin),
            },
          )
        : i18next.t(
            `prejudice.prejudicesTypes.DEPENSE_SANTE_FUTURES.writtenPrint.depense.recurrente.frequence.noDateDeFin`,
            {
              dateDebut: row.dateDebut ? fDate(row.dateDebut) : '',
            },
          ));

    const tiersPayeurText =
      intro +
      i18next.t(
        `prejudice.prejudicesTypes.DEPENSE_SANTE_FUTURES.writtenPrint.depense.recurrente.tiersPayeur.intro`,
      ) +
      row.priseEnChargeTiersPayeurs
        .filter((row) => row.montant !== 0)
        .map((row, index, array) => {
          const tierPayeurText = i18next.t(
            `prejudice.prejudicesTypes.DEPENSE_SANTE_FUTURES.writtenPrint.depense.recurrente.tiersPayeur.tierPayeur`,
            {
              tierPayeur: row.tiersPayeur,
              priseEnChargeTierPayeur: fCurrency(row.montant),
            },
          );
          return array.length === 1
            ? tierPayeurText
            : index === array.length - 1
              ? ` et${tierPayeurText}.`
              : tierPayeurText;
        });

    const noTiersPayeur =
      intro +
      i18next.t(
        `prejudice.prejudicesTypes.DEPENSE_SANTE_FUTURES.writtenPrint.depense.recurrente.tiersPayeur.noTiersPayeur`,
      );

    const resteAChargeText = getResteAChargeText(row, 'recurrente', index);

    const [coefficientLiquidation, coefficientDateAttribution] =
      getAllCapitalisationCoefficient({
        sexe: victime.sexe,
        dateNaissance: new Date(victime.dateNaissance || 0),
        dateLiquidation: new Date(procedure.dateLiquidation || 0),
        baremeValues: (bareme?.values as BaremeCapitalisationValue[]) ?? [],
        ageDateAttribution:
          getAgeDateAttribution({
            dateNaissance: new Date(victime.dateNaissance || 0),
            dateLiquidation: new Date(procedure.dateLiquidation || 0),
            dateDeces: new Date(victime.dateDeces || 0),
            dateAttribution: new Date(row.capitalisation.dateAttribution || 0),
          })?.age || 0,
        ageDernierArrerage: row.capitalisation.ageDernierArrerage ?? 0,
        isDernierArrerageViager: row.capitalisation.isLastArrerageViager,
      });

    const capitalisationIntro = i18next.t(
      `prejudice.prejudicesTypes.DEPENSE_SANTE_FUTURES.writtenPrint.depense.capitalisation.viagere.Intro`,
      {
        montant: fCurrency(row.montantUnitaireAnnualise || 0),
        date: fDate(new Date(row.capitalisation.dateAttribution || 0)),
        age:
          getAgeDateAttribution({
            dateNaissance: new Date(victime.dateNaissance || 0),
            dateLiquidation: new Date(procedure.dateLiquidation || 0),
            dateDeces: new Date(victime.dateDeces || 0),
            dateAttribution: new Date(row.capitalisation.dateAttribution || 0),
          })?.age ?? null,
        frequence:
          row.frequenceMontant == 'viagere'
            ? i18next.t(
                `prejudice.prejudicesTypes.DEPENSE_SANTE_FUTURES.writtenPrint.depense.recurrente.frequence.annee_nocomma`,
                {
                  value: row.renouvellementMaintenance,
                },
              )
            : i18next.t(
                `prejudice.prejudicesTypes.DEPENSE_SANTE_FUTURES.writtenPrint.depense.recurrente.frequence.periode_nocomma`,
                {
                  value: row.quantite,
                  frequenceMontant: row.frequenceMontant,
                },
              ),
        revalorisation:
          procedure.dateLiquidation &&
          row.montantUnitaireAnnualise &&
          !row.montantsDejaRevalorises &&
          row.dateJustificatif
            ? getMontantRevaloriseWrittenPrint({
                montant: row.montantUnitaireAnnualise,
                anneeOrDateLiquidation: procedure.dateLiquidation,
                anneeOrDateMontant: row.dateJustificatif || '',
                coefficientsType: formData.revalorisationCoefficientsType,
                monetaryErosions,
              })
            : '',
        dateAttributionType: row.capitalisation.dateAttributionType,
      },
    );

    const capitalisationConclusion = i18next.t(
      `prejudice.prejudicesTypes.DEPENSE_SANTE_FUTURES.writtenPrint.depense.capitalisation.conclusion`,
      {
        montantCapitalise: fCurrency(row.capitalisation.montantCapitalise),
        formule: displayFormula({
          formula:
            formData.capitalisation.enableCapitalisationDifferee &&
            coefficientDateAttribution
              ? [
                  fCurrency(row.montantUnitaireAnnualise ?? 0),
                  [
                    `(${fCoefficientCapitalisation(coefficientLiquidation ?? 0)}`,
                    `${fCoefficientCapitalisation(coefficientDateAttribution)})`,
                  ].join(` ${'-'} `),
                ].join(` ${'x'} `)
              : [
                  fCurrency(row.montantUnitaireAnnualise ?? 0),
                  fCoefficientCapitalisation(
                    row.capitalisation.coefficient ?? 0,
                  ),
                ].join(` ${'x'} `),
          options: { withParentheses: true },
          editedFieldsParameters: {
            fieldName: `rows.${index}.capitalisation`,
            formData,
          },
        }),
      },
    );

    const creanceTP = i18next.t(
      `prejudice.prejudicesTypes.DEPENSE_SANTE_FUTURES.writtenPrint.depense.capitalisation.creanceTP`,
    );

    const nonZeroCapitalisationTiersPayeurs =
      row.capitalisationTiersPayeurs.parTiersPayeur.filter(
        (tierPayeur) => tierPayeur.montantCapitalise !== 0,
      );

    const getTierPayeurCapitalisationText = (
      tierPayeur: {
        tiersPayeur: string;
        sommeACapitaliser: number;
      } & Omit<
        CapitalisationValues,
        'isLastArrerageViager' | 'bareme' | 'ageDernierArrerage'
      >,
    ): string =>
      i18next.t(
        `prejudice.prejudicesTypes.DEPENSE_SANTE_FUTURES.writtenPrint.depense.capitalisation.tierPayer`,
        {
          montantCapitalise: fCurrency(tierPayeur.montantCapitalise),
          tierPayer: tierPayeur.tiersPayeur,
        },
      );

    if (nonZeroPriseEnChargeTiersPayeurs.length === 0) {
      return getParagraph({
        numbering: {
          reference: NumberingType.SIMPLE_LIST,
          level: 0,
        },
        children: [
          ...getTextRun(noTiersPayeur),
          ...(row.capitalisation.montantCapitalise === 0
            ? []
            : [
                ...getTextRun(capitalisationIntro),
                ...getTextRun(capitalisationConclusion),
                ...(nonZeroCapitalisationTiersPayeurs.length > 0
                  ? [
                      ...getTextRun(creanceTP),
                      ...nonZeroCapitalisationTiersPayeurs.flatMap(
                        (tierPayeur) => [
                          ...getTextRun(
                            getTierPayeurCapitalisationText(tierPayeur),
                          ),
                        ],
                      ),
                    ]
                  : []),
              ]),
        ],
      });
    } else {
      return getParagraph({
        numbering: {
          reference: NumberingType.SIMPLE_LIST,
          level: 0,
        },
        children: [
          ...getTextRun(tiersPayeurText),
          ...((
            row.dateDebut && procedure.dateLiquidation
              ? row.dateDebut < procedure.dateLiquidation
              : false
          )
            ? getTextRun(resteAChargeText)
            : []),
          ...(row.capitalisation.montantCapitalise === 0
            ? []
            : [
                ...getTextRun(capitalisationIntro),
                ...getTextRun(capitalisationConclusion),
                ...(nonZeroCapitalisationTiersPayeurs.length > 0
                  ? [
                      ...getTextRun(creanceTP),
                      ...nonZeroCapitalisationTiersPayeurs.flatMap(
                        (tierPayeur) => [
                          ...getTextRun(
                            getTierPayeurCapitalisationText(tierPayeur),
                          ),
                        ],
                      ),
                    ]
                  : []),
              ]),
        ],
      });
    }
  };

  const recapitulatif = simpleTablePrint(
    [
      i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.tableRecapitulatif.fields.victime.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.tableRecapitulatif.fields.tiersPayeurs.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.tableRecapitulatif.fields.difference.columnHeader',
      ),
    ],
    [
      i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.tableRecapitulatif.fields.echus.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.tableRecapitulatif.fields.echoir.columnHeader',
      ),
      i18next.t(
        'prejudice.prejudicesFormTypes.CALENDRIER_DEPENSE_CAPITALISATION.tableRecapitulatif.fields.montantsCapitalises.columnHeader',
      ),
    ],
    [
      [
        `${fCurrency(totauxDepenses.montantTotal)}`,
        `${fCurrency(totauxDepensesTiersPayeurs)}`,
        `${fCurrency(totauxDepenses.resteACharge)}`,
      ],
      [
        `${fCurrency(totauxDepensesAEchoir.montantTotal)}`,
        `${fCurrency(totauxDepensesTiersPayeursAEchoir)}`,
        `${fCurrency(totauxDepensesAEchoir.resteACharge)}`,
      ],
      [
        `${fCurrency(formData.capitalisation.montantCapitalise)}`,
        `${fCurrency(formData.capitalisationTiersPayeurs.montantCapitalise)}`,
        `${fCurrency(formData.capitalisation.montantCapitalise - formData.capitalisationTiersPayeurs.montantCapitalise)}`,
      ],
    ],
  );

  const indemnitesRepartieAEchoir =
    CalculsFormCapitalisation.getIndemnitesRepartie({
      rows: formData.rows,
      capitalisation: formData.capitalisation,
      partResponsabilite: procedure.partResponsabilite,
      tiersPayeurs: procedure.tiersPayeurs,
      dateLiquidation: new Date(procedure.dateLiquidation ?? 0),
      monetaryErosions,
      revalorisationCoefficientsType: formData.revalorisationCoefficientsType,
      capitalisationTiersPayeurs: formData.capitalisationTiersPayeurs,
      dateConsolidation: new Date(procedure.dateConsolidation ?? 0),
      dateDeces: undefined,
    });

  const totalPriseEnChargeTiersPayeurs = CalculsGlobal.sum(
    Object.values(totauxDepenses.priseEnChargeTiersPayeurs).map(
      (priseEnChargeTiersPayeurs) => priseEnChargeTiersPayeurs,
    ),
  );

  const nonZeroRows = formData.rows.filter(
    (row) => !!row.montantTotal || !!row.resteACharge,
  );

  const allTotaux = [
    totauxDepensesAEchoir.resteACharge,
    totauxDepenses.resteACharge,
    totauxDepensesTiersPayeurs,
    totauxDepensesTiersPayeursAEchoir,
    formData.capitalisation.montantCapitalise,
  ];

  const filteredAllTotaux = allTotaux
    .filter((value) => value !== 0)
    .map((value) => fCurrency(value))
    .join(' + ');

  return [
    getEmptyLine(),
    getParagraph(formData.notes),
    getEmptyLine(),
    ...(nonZeroRows.length > 0
      ? [
          ...capitalisationSelectedBaremeIntroductionWrittenPrint(
            bareme,
            formData.capitalisation.enableCapitalisationDifferee,
          ),
          getEmptyLine(),
          ...getRevalorisationIntroductionWrittenPrintIfRevalorise({
            formType: 'CALENDRIER_DEPENSE_CAPITALISATION',
            revalorisationCoefficientsType:
              formData.revalorisationCoefficientsType,
            formData,
          }),
          getParagraph(
            i18next.t(
              `prejudice.prejudicesTypes.DEPENSE_SANTE_FUTURES.writtenPrint.depense.intro`,
            ),
          ),
          ...formData.rows.flatMap((row, index) => [
            row.type === 'ponctuelle'
              ? getDepensePonctuelleParagraphs(row, index)
              : getDepenseRecurrenteParagraphs(row, index),
          ]),
        ]
      : []),
    getEmptyLine(),
    recapitulatif,
    getEmptyLine(),
    ...indemniteGlobaleARepartirAEchoirWrittenPrint({
      indemniteRepartie: indemnitesRepartieAEchoir,
      partResponsabilite: procedure.partResponsabilite,
      tiersPayeurs: procedure.tiersPayeurs,
      victime,
      indemniteGlobaleFormula:
        totauxDepenses.resteACharge && totalPriseEnChargeTiersPayeurs
          ? filteredAllTotaux
          : '',
      firstPart: i18next.t(
        `prejudice.prejudicesTypes.${prejudiceType}.writtenPrint.indemniteGlobaleARepartir.firstPart`,
      ),
    }),
    ...(formData.isRentesOption
      ? [
          getEmptyLine(),
          ...rentesPrint({
            montant: formData.rentes?.montant || 0,
          }),
        ]
      : []),
  ];
};
