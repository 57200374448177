import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  getOrganizationOwnerEmailDomain,
  hasOrganizationAvailableLicences,
} from 'src/helpers/organization';
import {
  Organization,
  OrganizationMember,
  OrganizationMemberRole,
  OrganizationType,
} from 'src/types/organization.type';
import * as yup from 'yup';
import { SelectFieldForm } from '../forms/SelectFieldForm';
import { TextFieldForm } from '../forms/TextFieldForm';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { upperFirst } from 'lodash';
import { fDate } from 'src/helpers/formatTime';
import { fCurrency } from 'src/helpers/formatNumber';
import { memberMonthlySubscriptionPrice } from 'src/constants/subscription';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { updateOrganizationMemberRole } from 'src/slices/organization';
import { createOrganizationInvitation } from 'src/slices/organizationInvitation';
import { UpdateOrganizationPaymentMethod } from './UpdateOrganizationPaymentMethod';
import { useInvitationsToOrganization } from 'src/hooks/store/organizationInvitation.hooks';

type AddOrUpdateMemberFormData = {
  email: string;
  role: OrganizationMemberRole;
};

const AddOrUpdateMemberForm: React.FC<{
  onSubmit: (data: AddOrUpdateMemberFormData) => void;
  memberToUpdate: OrganizationMember | null;
  organization: Organization;
  areLicencesAvailable: boolean;
}> = ({ memberToUpdate, organization, areLicencesAvailable, onSubmit }) => {
  const { t } = useTranslation();
  const [isPaymentAccepted, setIsPaymentAccepted] = useState(false);
  const ownerEmailDomain = getOrganizationOwnerEmailDomain(organization);
  const { addMemberAmount } = useAppSelector(({ subscription }) => ({
    addMemberAmount: subscription.addMemberAmount,
  }));
  const { control, handleSubmit } = useForm<AddOrUpdateMemberFormData>({
    defaultValues: {
      email: memberToUpdate?.email ?? '',
      role: memberToUpdate?.role ?? OrganizationMemberRole.member,
    },
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().when([], ([], schema) => {
          if (memberToUpdate) {
            return schema.notRequired();
          }
          const updatedSchema = schema
            .email()
            .required()
            .notOneOf(
              organization.members.map((member) => member.email),
              t(
                'pages.OrganizationConfiguration.AddOrUpdateMemberForm.email.alreadyMember',
              ),
            );
          if (organization.forbidNonOrganizationEmails) {
            return updatedSchema.matches(
              new RegExp(`@${ownerEmailDomain}$`),
              t(
                'pages.OrganizationConfiguration.AddOrUpdateMemberForm.email.invalidDomain',
                {
                  ownerEmailDomain,
                },
              ),
            );
          }
          return updatedSchema;
        }),
        role: yup
          .string()
          .oneOf(Object.values(OrganizationMemberRole))
          .required(),
      }),
    ),
  });
  return (
    <Stack
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      spacing={2}
      paddingTop={1}
    >
      {memberToUpdate ? (
        <Typography>
          {upperFirst(memberToUpdate.firstName)}{' '}
          {upperFirst(memberToUpdate.lastName)}
        </Typography>
      ) : null}
      {!memberToUpdate ? (
        <TextFieldForm
          control={control}
          name="email"
          label="Addresse e-mail"
          sx={{ marginTop: 2 }}
        />
      ) : null}
      <Typography>
        {t('pages.OrganizationConfiguration.AddOrUpdateMemberForm.roleTitle')}
      </Typography>
      <SelectFieldForm
        control={control}
        name="role"
        options={[
          OrganizationMemberRole.admin,
          OrganizationMemberRole.member,
        ].map((role) => ({
          value: role,
          label: (
            <Stack>
              <Typography>
                {t(`organization.fields.role.options.${role}.label`)}
              </Typography>
              <Typography
                variant="caption"
                whiteSpace="pre-wrap"
                overflow="hidden"
                maxWidth={306}
              >
                {t(`organization.fields.role.options.${role}.description`)}
              </Typography>
            </Stack>
          ),
        }))}
      />
      {organization.type === OrganizationType.CABINET_AVOCAT &&
      !!organization.subscription.isAutomaticSubscriptionPaymentActive &&
      !memberToUpdate &&
      !areLicencesAvailable ? (
        <Stack direction="row" spacing={2} alignItems="center">
          <Checkbox
            checked={isPaymentAccepted}
            onChange={() => setIsPaymentAccepted(!isPaymentAccepted)}
          />
          <Typography>
            {t(
              'pages.OrganizationConfiguration.AddOrUpdateMemberForm.acceptPayment',
              {
                pricePerMonth: fCurrency(memberMonthlySubscriptionPrice),
                subscriptionPeriodEndDate: organization.subscription
                  .subscriptionPeriodEndDate
                  ? fDate(organization.subscription.subscriptionPeriodEndDate)
                  : '',
                totalPrice: fCurrency(addMemberAmount / 100),
              },
            )}
          </Typography>
        </Stack>
      ) : null}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={
          organization.type === OrganizationType.CABINET_AVOCAT &&
          !!organization.subscription.isAutomaticSubscriptionPaymentActive &&
          !memberToUpdate &&
          !areLicencesAvailable &&
          !isPaymentAccepted
        }
      >
        {t('forms.save')}
      </Button>
    </Stack>
  );
};

interface Props {
  open: boolean;
  memberToUpdate: OrganizationMember | null;
  organization: Organization;
  onClose: (createdInvitation?: true) => void;
}

export const AddOrUpdateMemberDialog: React.FC<Props> = ({
  open,
  memberToUpdate,
  organization,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isPaymentMethodMissingError } = useAppSelector(
    ({ organizationInvitation }) => ({
      isPaymentMethodMissingError:
        organizationInvitation.isPaymentMethodMissingError,
    }),
  );
  const invitations = useInvitationsToOrganization();
  const areLicencesAvailable = hasOrganizationAvailableLicences(
    organization,
    invitations,
  );
  const onSubmit = (data: AddOrUpdateMemberFormData) => {
    if (memberToUpdate) {
      dispatch(
        updateOrganizationMemberRole({
          organizationId: organization._id,
          memberId: memberToUpdate._id,
          dto: { role: data.role },
        }),
      );
      onClose();
    } else {
      dispatch(
        createOrganizationInvitation({
          organizationId: organization._id,
          data: {
            email: data.email,
            role: data.role,
          },
        }),
      );
      onClose(true);
    }
  };

  const onClickContactUs = () => {
    navigate(`/organization/${organization._id}/quote`);
  };
  return (
    <Dialog open={open} onClose={() => onClose()}>
      {!open ? null : (
        <>
          <DialogTitle>
            {memberToUpdate
              ? t(
                  'pages.OrganizationConfiguration.AddOrUpdateMemberForm.updateMember',
                )
              : areLicencesAvailable ||
                  organization.type === OrganizationType.CABINET_AVOCAT
                ? t(
                    'pages.OrganizationConfiguration.AddOrUpdateMemberForm.addMember',
                  )
                : t(
                    'pages.OrganizationConfiguration.AddOrUpdateMemberForm.addMemberInsuranceWithoutLicence',
                  )}
          </DialogTitle>
          <DialogContent sx={{ minWidth: 400 }}>
            {areLicencesAvailable ||
            (organization.type === OrganizationType.CABINET_AVOCAT &&
              !!organization.subscription
                .isAutomaticSubscriptionPaymentActive) ||
            !!memberToUpdate ? (
              isPaymentMethodMissingError ? (
                <UpdateOrganizationPaymentMethod
                  organizationId={organization._id}
                />
              ) : (
                <AddOrUpdateMemberForm
                  onSubmit={onSubmit}
                  organization={organization}
                  memberToUpdate={memberToUpdate}
                  areLicencesAvailable={areLicencesAvailable}
                />
              )
            ) : (
              <Stack justifyContent="center" alignItems="center">
                <Typography>
                  {t(
                    'pages.OrganizationConfiguration.AddOrUpdateMemberForm.noLicenceAvailable',
                    {
                      count:
                        (organization.subscription.maxNumberOfUsers || 0) + 1,
                    },
                  )}
                  {t(
                    'pages.OrganizationConfiguration.AddOrUpdateMemberForm.purchaseLicenceOrRemoveMember',
                  )}
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => onClose()}
                  style={{ marginTop: 10, width: 300 }}
                >
                  {t(
                    'pages.OrganizationConfiguration.AddOrUpdateMemberForm.removeMember',
                  )}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 10, width: 300 }}
                  onClick={onClickContactUs}
                >
                  {t(
                    'pages.OrganizationConfiguration.AddOrUpdateMemberForm.contactUs',
                  )}
                </Button>
              </Stack>
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};
